<template>
  <div class="uk-form-stacked">
    <div
      v-if="loadingDetail"
      class="uk-flex uk-flex-center uk-flex-middle"
    >
      <div uk-spinner />
    </div>
    <div
      v-else
      class="uk-child-width-expand@s uk-grid-medium"
      uk-grid
    >
      <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
        <div
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              VCF Code
            </label>
            <div class="uk-form-controls uk-flex">
              <div class="uk-width-1-2 uk-margin-right">
                <input
                  v-model="first_vcf_code"
                  name="vcf_code"
                  class="uk-input"
                  type="text"
                  autocomplete="off"
                  placeholder="Enter VCF Code"
                  disabled
                  :class="{'uk-form-danger': errors.has('vcf_code')}"
                >
              </div>
              <div class="uk-width-1-2">
                <input
                  v-model="second_vcf_code"
                  v-validate="'required|max:50'"
                  name="vcf_code"
                  class="uk-input"
                  type="text"
                  autocomplete="off"
                  placeholder="Enter VCF Code"
                  :class="{'uk-form-danger': errors.has('vcf_code')}"
                >
                <span
                  v-show="errors.has('vcf_code')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('vcf_code') }}</span>
              </div>
            </div>
          </div>
          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Export Hub
            </label>
            <div class="uk-form-controls">
              <multiselect
                v-model="detailPlantation.export_hub"
                v-validate="'required'"
                label="name" 
                name="export_hub" 
                track-by="id"
                placeholder="Select Export Hub or Type to Search" 
                open-direction="bottom" 
                :options="listExportHub.data" 
                :searchable="true"
                :max-height="200"
                :show-no-results="true"
                @search-change="(input) => handleSearchDropdown('export', listExportHub.data, input)"
                @remove="() => recallApiList('export', false)"
                @select="(data) => recallApiList('export', data)"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
            </div>
            <span
              v-show="errors.has('export_hub')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('export_hub') }}</span>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Pulping Facility
            </label>
            <div class="uk-form-controls">
              <multiselect
                v-model="detailPlantation.pulping_facility"
                v-validate="'required'"
                label="facility_name"
                name="pulping_facility"
                track-by="id"
                placeholder="Select Pulping Facility or Type to Search"
                open-direction="bottom"
                :options="listPulping.data"
                :searchable="true"
                :max-height="200"
                :show-no-results="true"
                @search-change="(input) => handleSearchDropdown('pulping', listPulping.data, input)"
                @remove="() => recallApiList('pulping', false)"
                @select="(data) => recallApiList('pulping', data)"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
            </div>
            <span
              v-show="errors.has('pulping_facility')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('pulping_facility') }}</span>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Species Type
            </label>
            <div class="uk-form-controls">
              <multiselect 
                v-model="detailPlantation.species"
                v-validate="'required'"
                label="name"
                name="species_type"
                track-by="id"
                placeholder="Select Species Type"
                open-direction="bottom"
                :options="listSpeciesType"
                :searchable="true"
                :max-height="200"
                :show-no-results="true"
                @select="handleSelectSpecies"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
            </div>
            <span
              v-show="errors.has('species_type')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('species_type') }}</span>
          </div>

          <div class="uk-width-1-1">
            <div class="uk-form-label">
              Size
            </div>
            <div class="uk-width-1-1 fields-custom">
              <input
                v-model="formPlantation.size"
                v-validate="'required|max:50'"
                name="size"
                class="uk-input"
                type="text"
                autocomplete="off"
                placeholder="Enter Size"
                onkeypress="return /^[0-9 +]*$/i.test(event.key)"
                :class="{'uk-form-danger': errors.has('size')}"
              >
              <label class="label-custom">Hectare</label>
            </div>
            <span
              v-show="errors.has('size')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('size') }}</span>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Latitude
            </label>
            <div class="uk-form-controls">
              <input
                v-model="formPlantation.latitude"
                v-validate="'required|max:100'"
                name="latitude"
                class="uk-input"
                type="text"
                autocomplete="off"
                placeholder="Enter Latitude"
                :class="{'uk-form-danger': errors.has('latitude')}"
              >
            </div>
            <span
              v-show="errors.has('latitude')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('latitude') }}</span>
          </div>

          <div class="uk-width-1-1">
            <div class="uk-form-label">
              Potensi Panen
            </div>
            <div class="uk-width-1-1 fields-custom">
              <input
                v-model="formPlantation.harvest_potential"
                v-validate="'required|max:10'"
                name="harvest_potential"
                class="uk-input"
                type="text"
                autocomplete="off"
                placeholder="Enter Size"
                onkeypress="return /^[0-9 +]*$/i.test(event.key)"
                :class="{'uk-form-danger': errors.has('harvest_potential')}"
              >
              <label class="label-custom">Kg</label>
            </div>
            <span
              v-show="errors.has('harvest_potential')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('harvest_potential') }}</span>
          </div>
        </div>
      </div>
          
      <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
        <div
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Facility Name
            </label>
            <div class="uk-form-controls">
              <input
                v-model="formPlantation.facility_name"
                v-validate="'required|max:50'"
                name="facility_name"
                class="uk-input"
                type="text"
                autocomplete="off"
                placeholder="Enter Facility Name"
                :class="{'uk-form-danger': errors.has('facility_name')}"
              >
            </div>
            <span
              v-show="errors.has('facility_name')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('facility_name') }}</span>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Hulling Facility
            </label>
            <div class="uk-form-controls">
              <multiselect 
                v-model="detailPlantation.hulling_facility"
                v-validate="'required'"
                label="facility_name"
                name="hulling_facility"
                track-by="id"
                placeholder="Select Hulling Facility or Type to Search"
                open-direction="bottom"
                :options="listHulling.data"
                :searchable="true"
                :max-height="200"
                :show-no-results="true"
                @search-change="(input) => handleSearchDropdown('hulling', listHulling.data, input)"
                @remove="() => recallApiList('hulling', false)"
                @select="(data) => recallApiList('hulling', data)"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
            </div>
            <span
              v-show="errors.has('hulling_facility')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('hulling_facility') }}</span>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Varieties Type
            </label>
            <div class="uk-form-controls">
              <multiselect 
                v-model="detailPlantation.varieties"
                v-validate="'required'"
                label="name"
                name="varieties_type"
                track-by="id"
                placeholder="Select Varieties Type"
                open-direction="bottom"
                :options="listVarietiesType"
                :searchable="true"
                :max-height="200"
                :show-no-results="true"
                @select="handleSelectVarieties"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
            </div>
            <span
              v-show="errors.has('varieties_type')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('varieties_type') }}</span>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Product Type
            </label>
            <div class="uk-form-controls">
              <multiselect 
                v-model="detailPlantation.product_type"
                v-validate="'required'"
                label="name"
                name="product_type"
                track-by="id"
                placeholder="Select Product Type"
                open-direction="bottom"
                :options="listProductType"
                :searchable="true"
                :max-height="200"
                :show-no-results="true"
                @select="handleSelectProductType"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
            </div>
            <span
              v-show="errors.has('product_type')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('product_type') }}</span>
          </div>

          <div class="uk-width-1-1">
            <div class="uk-form-label">
              Tree
            </div>
            <div class="uk-width-1-1 fields-custom">
              <input
                v-model="formPlantation.tree"
                v-validate="'required|max:50'"
                name="tree"
                class="uk-input"
                type="text"
                autocomplete="off"
                placeholder="Enter Tree"
                onkeypress="return /^[0-9 +]*$/i.test(event.key)"
                :class="{'uk-form-danger': errors.has('tree')}"
              >
              <label class="label-custom">Tree</label>
            </div>
            <span
              v-show="errors.has('tree')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('tree') }}</span>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Longitude
            </label>
            <div class="uk-form-controls">
              <input
                v-model="formPlantation.longitude"
                v-validate="'required|max:100'"
                name="longitude"
                class="uk-input"
                type="text"
                autocomplete="off"
                placeholder="Enter Longitude"
                :class="{'uk-form-danger': errors.has('longitude')}"
              >
            </div>
            <span
              v-show="errors.has('longitude')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('longitude') }}</span>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Address
            </label>
            <div class="uk-form-controls">
              <input
                v-model="formPlantation.address"
                v-validate="'required|max:100'"
                name="address"
                class="uk-input"
                type="text"
                autocomplete="off"
                placeholder="Enter Address"
                :class="{'uk-form-danger': errors.has('address')}"
              >
            </div>
            <span
              v-show="errors.has('address')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('address') }}</span>
          </div>
        </div>
      </div>

      <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-1@l">
        <div
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Status
            </label>
            <div class="uk-form-controls">
              <label class="switch">
                <input
                  v-if="formPlantation.status == 1"
                  class="switcher"
                  name="status"
                  type="checkbox"
                  checked
                  @change="handleStatus"
                >
                <input
                  v-else
                  class="switcher"
                  name="status"
                  type="checkbox"
                  @change="handleStatus"
                >
                <span class="slider round" />
              </label>
              <span :class="formPlantation.status == 1 ? 'label-switcher active ' : 'label-switcher'">{{ formPlantation.status == 1 ? status.ACTIVE : status.INACTIVE }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
                
    <div class="uk-flex uk-flex-right uk-margin-medium-top">
      <button
        class="uk-button soft-green uk-margin-right"
        @click="showCancelConfirmModal"
      >
        Cancel
      </button>
      <button
        class="uk-button green"
        :class="isCanAccessUser('edit', 'PLANTATION') ? '' : 'disabled'"
        :disabled="!isCanAccessUser('edit', 'PLANTATION')"
        @click="showSaveConfirmModal"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { currencyDigit } from '@/utils/formater'
import { STATUS } from '@/utils/constant'
import { isCanAccess } from '@/utils/auth'

export default {
  props: {
    id: {
      required: true,
      type: String
    },
    loadingDetail: {
      required: false,
      type: Boolean
    },
    listVarietiesType: {
      required: true,
      type: Array
    },
    listSpeciesType: {
      required: true,
      type: Array
    },
    listProductType: {
      required: true,
      type: Array
    },
    listExportHub: {
      required: true,
      type: Object
    },
    listHulling: {
      required: true,
      type: Object
    },
    listPulping: {
      required: true,
      type: Object
    },
    setToggleModalAddEditDiscard: {
      required: true,
      type: Function
    },
    getToggleModalAddEditDiscard: {
      required: true,
      type: Object
    },
    setDataModalAddEdit: {
      required: true,
      type: Function
    }
  },
  data() {
    return{
      status: STATUS,
      formPlantation:{},
      searchOn:false,
      first_vcf_code: '',
      second_vcf_code: ''
    }
  },
  computed: {
    ...mapGetters({
      detailPlantation: 'plantation/detail_plantation'
    })
  },
  watch: {
    async detailPlantation(val) {
      if(this.detailPlantation){
        this.formPlantation = {
          vcf_code: val.vcf_code || '',
          facility_name: val.facility_name || '',
          latitude: val.latitude || '',
          longitude: val.longitude || '',
          export_hub_id: val.export_hub_id || '',
          hulling_facility_id: val.hulling_facility_id || '',
          pulping_facility_id: val.pulping_facility_id || '',
          address: val.address || '',
          species_id: val.species_id || '',
          varieties_id: val.varieties_id || '',
          product_type_id: val.product_type_id || '',
          harvest_potential: val.harvest_potential || '',
          size: val.size || '',
          tree: val.tree || '',
          status: val.status || false
        }
        this.first_vcf_code = this.formPlantation.vcf_code.split('-').slice(0, 3).join('-')
        this.second_vcf_code = this.formPlantation.vcf_code.split('-')[3]
      }
    }
  },
  methods: {
    ...mapActions({
      actionGetListHulling: 'hullingFacility/getListHulling',
      actionGetListPulping: 'pulpingFacility/getListPulping',
      actionGetListExportHub: 'exportHub/getListExportHub'
    }),
    async handleSearchDropdown(list, data, input) {
      const dataList = data
  
      const checker = dataList.some((item) => {
        const lowerFacilityName = item.facility_name ? item.facility_name.toLowerCase() : item.name.toLowerCase()
        const lowerSearchQuery = input.toLowerCase()
        return lowerFacilityName.includes(lowerSearchQuery)
      })

      if (!checker && !(dataList.length < 1)) {
        this.searchOn = true
        switch (list) {
        case 'hulling':
          await this.actionGetListHulling({ search: input })
          break
        case 'pulping':
          await this.actionGetListPulping({ search: input })
          break
        default:
          await this.actionGetListExportHub({ search: input })
          break
        }
      }

      if (!input) {
        let i = input
        if(!(i.includes(input)) || dataList.length < 1) {
          switch (list) {
          case 'hulling':
            await this.actionGetListHulling({ page: 1, limit: 50 })
            break
          case 'pulping':
            await this.actionGetListPulping({ page: 1, limit: 50 })
            break
          default:
            await this.actionGetListExportHub({ page: 1, limit: 50 })
            break
          }
        }
      }
    },
    async recallApiList(list, data){
      if(this.searchOn){
        this.searchOn = false
        switch (list) {
        case 'hulling':
          await this.actionGetListHulling({ export_hub_id: this.detailPlantation.export_hub, page: 1, limit: 50 })
          break
        case 'pulping':
          await this.actionGetListPulping({ hulling_facility_id: this.detailPlantation.hulling_facility.id, page: 1, limit: 50 })
          break
        default:
          await this.actionGetListExportHub({ page: 1, limit: 50 })
          break
        }
      } else {
        if(data){
          switch (list) {
          case 'hulling':
            await this.actionGetListPulping({hulling_facility_id: data.id})
            this.detailPlantation.export_hub = this.listExportHub.data.filter(item => item.id == data.export_hub.id)[0]
            this.formPlantation.vcf_code = this.listHulling.data.filter(item => item.export_hub_id == data.id)[0].evc_code
            this.first_vcf_code = this.formPlantation.vcf_code.split('-').slice(0, 3).join('-')
            break
          case 'pulping':
            this.export_hub = this.listExportHub.data.filter(item => item.id == data.export_hub.id)[0]
            this.detailPlantation.hulling_facility = this.listHulling.data.filter(item => item.id == data.hulling_facility.id)[0]
            this.formPlantation.vcf_code = data.vc_code
            this.first_vcf_code = this.formPlantation.vcf_code.split('-').slice(0, 3).join('-')
            break
          default:
            await this.actionGetListHulling({export_hub_id: data.id})
            this.detailPlantation.hulling_facility = this.listHulling.data.filter(item => item.export_hub_id == data.id)[0]
            await this.actionGetListPulping({hulling_facility_id: this.detailPlantation.hulling_facility.id})
            this.detailPlantation.pulping_facility = null
            this.formPlantation.vcf_code = this.listHulling.data.filter(item => item.export_hub_id == data.id)[0].vc_code
            this.first_vcf_code = this.formPlantation.vcf_code.split('-').slice(0, 3).join('-')
            break
          }
        } else {
          switch (list) {
          case 'hulling':
            this.detailPlantation.pulping_facility = null
            await this.actionGetListHulling({ page: 1, limit: 50 })
            break
          case 'pulping':
            this.detailPlantation.hulling_facility = null
            this.detailPlantation.export_hub = null
            await this.actionGetListPulping({ page: 1, limit: 50 })
            break
          default:
            this.detailPlantation.hulling_facility = null
            this.detailPlantation.pulping_facility = null
            await this.actionGetListExportHub({ page: 1, limit: 50 })
            break
          }
        }
      }
    },
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    formatCurrency(val) {
      return currencyDigit(val)
    },
    handleStatus(event) {
      this.formPlantation.status = event.target.checked ? 1 : 0
    },
    handleSelectVarieties(val) {
      this.formPlantation.varieties_id = val?.id
    },
    handleSelectSpecies(val) {
      this.formPlantation.species_id = val?.id
    },
    handleSelectProductType(val) {
      this.formPlantation.product_type_id = val?.id
    },
    showCancelConfirmModal() {
      this.setToggleModalAddEditDiscard({
        targetModals: true,
        title: 'Cancel Confirmation', 
        description: 'All data will be lost if you leave. Are you sure want to leave?',
        customTitleBtnOk: 'Yes',
        customTitleBtnCancel: 'No',
        type: 'discard',
        route: 'Plantation',
        loadingTable: false,
        loadingPopUp: false
      })
    },
    showSaveConfirmModal() {
      this.$validator.validateAll().then((success) => {
        if (success) {
          this.formPlantation.vcf_code = this.first_vcf_code+'-'+this.second_vcf_code
          if (this.$validator.errors.any()) return
          this.setDataModalAddEdit(this.formPlantation)
          this.setToggleModalAddEditDiscard({
            targetModals: true,
            title: 'Save Confirmation', 
            description: 'Are you sure want to save this data?',
            customTitleBtnOk: 'Save',
            customTitleBtnCancel: 'Cancel',
            type: 'save',
            loadingTable: false,
            loadingPopUp: false
          })
        }
      })
    }
  }
}
</script>

<style scoped>
  .uk-form-label {
    font-family: 'interRegular';
    font-weight: 400;
    font-size: 16px !important;
    color: #000000;
    text-transform: none;
  }
  .fields-custom{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .label-custom{
    position: absolute;
    right: 1rem;
  }
  .uk-input:disabled{
    background: #F4F4F4 !important;
    color: #000000 !important;
  }
</style>
