<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div class="uk-width-1-1">
      <h1 class="uk-heading-line">
        {{ headerTitle }}
      </h1>
    </div>
    <div class="uk-card uk-card-default uk-margin-top base-card">
      <edit
        :id="id"
        :loading-detail="loadingDetail"
        :list-varieties-type="listVarietiesType"
        :list-species-type="listSpeciesType"
        :list-product-type="listProductType"
        :list-export-hub="listExportHub"
        :list-hulling="listHulling"
        :list-pulping="listPulping"
        :set-toggle-modal-add-edit-discard="setToggleModalAddEditDiscard"
        :get-toggle-modal-add-edit-discard="getToggleModalAddEditDiscard"
        :set-data-modal-add-edit="setDataModalAddEdit"
      />
    </div>
    <modal-add-edit-discard 
      :set-toggle-modal-add-edit-discard="setToggleModalAddEditDiscard"
      :get-toggle-modal-add-edit-discard="getToggleModalAddEditDiscard"
      :handle-modal-button="handleModalEdit"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { notificationSuccess } from '@/utils/notification'
import { PREFIX_IMAGE } from '@/utils/constant'
import Edit from './Edit'
import ModalAddEditDiscard from '@/components/globals/modals/ModalAddEditDiscard'
import getHeaderTitle from '@/utils/header-title'

export default {
  components: {
    Edit,
    ModalAddEditDiscard
  },
  data() {
    return {
      id: this.$route.params.id,
      images: PREFIX_IMAGE,
      loadingDetail: true
    }
  },
  computed: {
    ...mapGetters({
      listVarietiesType: 'masterData/list_varieties_type',
      listSpeciesType: 'masterData/list_species_type',
      listProductType: 'masterData/list_product_type',
      listExportHub: 'exportHub/list_export_hub',
      listHulling: 'hullingFacility/list_hulling',
      listPulping: 'pulpingFacility/list_pulping',
      getDataModalAddEdit: 'plantation/get_data_modal_add_edit',
      getToggleModalAddEditDiscard: 'plantation/get_toggle_modal_add_edit_discard'
    }),
    headerTitle() {
      return getHeaderTitle(this.$route.fullPath)
    }
  },
  async mounted() {
    this.loadingDetail
    await this.actionGetListVarietiesType({ data_type: 'varieties', limit: 50, is_all: true })
    await this.actionGetListSpeciesType({ data_type: 'species', limit: 50, is_all: true })
    await this.actionGetListProductType({ data_type: 'product_types', limit: 50, is_all: true })
    await this.actionGetListExportHub({ page: 1, limit: 10 })
    await this.actionGetListHulling({ page:1, limit: 10 })
    await this.actionGetListPulping({ page: 1, limit: 10 })
    await this.actionGetDetailPlantation(this.id)
    this.loadingDetail = false
  },
  methods: {
    ...mapActions({
      actionGetListVarietiesType: 'masterData/getListVarietiesType',
      actionGetListSpeciesType: 'masterData/getListSpeciesType',
      actionGetListProductType: 'masterData/getListProductType',
      actionGetListExportHub: 'exportHub/getListExportHub',
      actionGetListHulling: 'hullingFacility/getListHulling',
      actionGetListPulping: 'pulpingFacility/getListPulping',
      actionGetDetailPlantation: 'plantation/getDetailPlantation',
      updatePlantation: 'plantation/updatePlantation'
    }),
    ...mapMutations({
      setDataModalAddEdit: 'plantation/SET_DATA_MODAL_ADD_EDIT',
      setToggleModalAddEditDiscard: 'plantation/SET_TOGGLE_MODAL_ADD_EDIT_DISCARD'
    }),
    handleModalEdit() {
      this.setToggleModalAddEditDiscard({
        ...this.getToggleModalAddEditDiscard,
        type: 'save',
        loadingTable: false,
        loadingPopUp: true
      })
      const payload = {
        id: this.id,
        reqBody: {...this.getDataModalAddEdit}
      }
      this.updatePlantation(payload).then(result => {
        if (result.message == 'OK') {
          setTimeout(() => {
            this.setToggleModalAddEditDiscard({
              ...this.getToggleModalAddEditDiscard,
              loadingTable: false,
              loadingPopUp: false
            })
            notificationSuccess('Edit Plantation Successful')
          }, 500)
          setTimeout(() => {
            this.$router.push({ name: 'Plantation' })
            this.setToggleModalAddEditDiscard({
              targetModals: false,  
              title: '',
              description: '',
              type: '',
              customTitleBtnOk: '',
              customTitleBtnCancel: '',
              customClass: '',
              loadingTable: false,
              loadingPopUp: false
            })
          }, 1000)
        }
      })
    }
  }
}
</script>
